import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [];

export const dictionary = {
		"/": [5],
		"/app": [6,[2]],
		"/app/admin/availabilities": [7,[2]],
		"/app/admin/availability-changes": [8,[2]],
		"/app/admin/cancellations": [9,[2]],
		"/app/admin/invitations": [10,[2]],
		"/app/admin/leave": [11,[2]],
		"/app/admin/logs": [12,[2]],
		"/app/admin/open-shifts": [13,[2]],
		"/app/admin/quick-add": [14,[2]],
		"/app/admin/roles": [15,[2]],
		"/app/admin/rosters": [16,[2]],
		"/app/admin/rosters/edit/[roster]": [17,[2]],
		"/app/admin/settings": [18,[2]],
		"/app/admin/subscription": [19,[2]],
		"/app/admin/templates": [20,[2]],
		"/app/admin/templates/edit/[template]": [21,[2]],
		"/app/admin/users": [22,[2]],
		"/app/announcements": [23,[2,3]],
		"/app/announcements/create": [24,[2,3]],
		"/app/announcements/edit/[announcement]": [25,[2,3]],
		"/app/availability": [26,[2]],
		"/app/leave": [27,[2]],
		"/app/open-shifts": [28,[2]],
		"/app/profile": [29,[2]],
		"/app/shifts": [30,[2]],
		"/app/shifts/all": [31,[2]],
		"/auth/change-email": [32,[4]],
		"/auth/create-account": [33,[4]],
		"/auth/create-admin-account": [34,[4]],
		"/auth/login": [35,[4]],
		"/auth/logout": [36,[4]],
		"/auth/new-password": [37,[4]],
		"/auth/register": [38,[4]],
		"/auth/reset": [39,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';