import * as Sentry from '@sentry/sveltekit';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://6c45fb95ddfc24c0802d7d639d83205e@o4506600582545408.ingest.sentry.io/4506664241004544',
		tracesSampleRate: 0.01,
		profilesSampleRate: 0.01,
		replaysOnErrorSampleRate: 1.0,
		integrations: [new Sentry.BrowserProfilingIntegration()]
	});
}
export const handleError = Sentry.handleErrorWithSentry();
